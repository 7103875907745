import React, { useState } from 'react';
import { Link } from 'gatsby';
import { SuperBlocks } from '../../../../../config/certification-settings';
import AccordionProgressBar from '../../ui/Accordion/components/accordion-progress-bar';
import ArrowCircleRight from '../../../assets/icons/arrow-circle-right';
import { getSuperBlockTitleForMap } from '../../../utils/superblock-map-titles';
import ModalUnlockContent from '../../modals/ModaUnlockContent';
import { AccessLevel } from '../../../utils/enums/access-levels';
import { userCompletedFirstCheckpointChallenge } from '../../../utils/general-functions';

import './map-item.css';

type MapItems = {
  readonly superBlock: SuperBlocks;
  readonly countPhases: number;
  readonly countPhasesCompleted: number;
  readonly accessAllowed: boolean;
  readonly userAccess: string;
  readonly userCpf: string;
  readonly courseCode: string;
  readonly completedFirstChallenge: boolean;
  readonly completedChallengeIds: string[];
  readonly checkUserRegistration: (data: {
    cpf: string;
    courseCode: string;
  }) => void;
};

export default function MapItem({
  superBlock,
  countPhases,
  countPhasesCompleted,
  accessAllowed,
  userAccess,
  userCpf,
  courseCode,
  completedFirstChallenge,
  completedChallengeIds,
  checkUserRegistration
}: MapItems): JSX.Element {
  const [openModalUnlockContent, setOpenModalUnlockContent] = useState(false);

  const [trailNumber, trailTitle] =
    getSuperBlockTitleForMap(superBlock).split('. ');

  const handleClickTrailItem = () => {
    if (
      userAccess === AccessLevel.LimitedNacional &&
      userCompletedFirstCheckpointChallenge(completedChallengeIds) &&
      superBlock !== SuperBlocks.LogicaDeProgramacao
    ) {
      checkUserRegistration({ cpf: userCpf, courseCode });
      return;
    }

    if (
      userAccess !== AccessLevel.LimitedNacional &&
      userAccess !== AccessLevel.CompleteNacional
    ) {
      !accessAllowed && setOpenModalUnlockContent(true);
    }
  };

  const handleCloseUnlockContentModal = () => {
    setOpenModalUnlockContent(false);
  };

  return (
    <>
      <Link
        to={accessAllowed ? `/learn/${superBlock}/` : '#'}
        className={`trail-container ${
          accessAllowed ? '' : 'access-not-allowed'
        }`}
        onClick={handleClickTrailItem}
      >
        <div className='trail-status-progress'>
          <div className='trail-title'>
            <h2 className='trail-title-order'>{trailNumber}</h2>
            <h2 className='trail-title-text'>{trailTitle}</h2>
          </div>
          {accessAllowed && completedFirstChallenge && (
            <AccordionProgressBar
              completedCount={countPhasesCompleted}
              numberChallenges={countPhases}
              sentence='type2'
              progressSize='small'
            />
          )}
        </div>
        <ArrowCircleRight />
      </Link>
      <ModalUnlockContent
        show={openModalUnlockContent && userAccess === AccessLevel.LIMITED}
        onHide={handleCloseUnlockContentModal}
      />
    </>
  );
}
